import React from 'react';

import useStatusBarColor from '../../lib/hooks/useStatusBarColor';

const StatusBarForiOS = () => {
  useStatusBarColor();
  return <></>;
};

export default StatusBarForiOS;
